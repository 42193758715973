@import "./../../styles/core/core-variable";

.progress-bar {
    height: 4px;
    background-color: $secondary-color;
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
}

.progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: $background-primary-color;
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
    0% {
        transform: translateX(0) scaleX(0);
    }

    40% {
        transform: translateX(0) scaleX(0.4);
    }

    100% {
        transform: translateX(100%) scaleX(0.5);
    }
}
