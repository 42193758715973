@import "./../../styles/core/core-variable";

header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $background-primary-color;
    padding: 14px;
    // border-bottom: 1px solid $secondary-color;

    .title {
        width: 150px;
        margin: auto;
    }
}