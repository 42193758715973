@mixin dynamic-property($name, $attribute, $start, $end) {
    @for $var from $start to $end {
        .#{$name}-#{$var} {
            #{$attribute}: #{(0.25*$var)}rem;
        }
    }
}

@mixin dynamic-width-property($name, $attribute, $start, $end, $suffix) {
    @for $var from $start to $end {
        .#{$name}-#{$var*10} {
            #{$attribute}: #{(10*$var)}+$suffix;

            @if $suffix =='%' {
                @media only screen and (max-width: 1000px) {
                    #{$attribute}: 100% !important;
                }

                @media only screen and (max-width: 1500px) {
                    #{$attribute}: #{(10*$var + 12)}+$suffix;
                }
            }
        }
    }
}