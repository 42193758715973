@import "../../styles/core/core-variable";


.ui.modal {
  background-color: $white-color;

  .content {
    padding: $padding-6;
    &.nopadding {
      padding: $padding-0;
    }
  }
}