.text {
    &-xs {
        font-size: $xs-font;
    }

    &-sm {
        font-size: $sm-font;
    }

    &-md {
        font-size: $md-font;
    }

    &-lg {
        font-size: $lg-font;
    }

    &-x1lg {
        font-size: $x1lg-font;
    }

    &-x2lg {
        font-size: $x2lg-font;
    }

    &-x3lg {
        font-size: $x3lg-font;
    }

    &-x4lg {
        font-size: $x4lg-font;
    }

    // align
    &-align-left {
        text-align: left;
    }

    &-align-center {
        text-align: center;
    }

    &-align-right {
        text-align: right;
    }

    // font-weight

    &-bold {
        font-weight: bold;
    }

    &-light {
        font-weight: 400;
    }

    // font-color
    &-primary-color {
        color: $primary-color;
    }

    &-secondary-color {
        color: $secondary-color;
    }

    &-default-color {
        color: $default-color;
    }

    &-success-color {
        color: $success-color;
    }

    &-danger-color {
        color: $danger-color;
    }

    &-white-color {
        color: $white-text;
    }

    &-grey-color {
        color: $grey-text;
    }

    &-underline {
        text-decoration: underline;
    }

    &-decoration-none {
        text-decoration: none;
    }

    &-hover-underline:hover {
        text-decoration: underline;
    }

    &-hover-primary:hover {
        color: $primary-color-hover;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-word-break {
        word-break: break-all;
    }

    &-inline {
        white-space: nowrap;
    }

    &-italic {
        font-style: italic;
    }

    //Form Error
    &-form-error {
        color: $danger-color;
        font-size: $md-font;
        font-weight: 500;
        margin-top: 4px;
    }
}

// a {
//     color: $primary-color;
// }

// a:hover {
//     color: $primary-color-hover;
// }