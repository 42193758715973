@import "./../../styles/core/core-variable";

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 70px;
    border-top: 1px solid $secondary-color;
    padding: 14px;
    background-color: $background-color;
}

.footer-body {

    color: $secondary-color;
    font-size: $md-font;

    .footer-body__link:hover {
        color: $primary-color;
    }
}