// Font Family
$primary-font: sans-serif;

// Color Variables
$primary-color: #197A56; //#2185d0;
$default-color: #000000;
$secondary-color: #999999;
$background-color: #ffffff;
$warning-color: #f0ad4e;
$info-color: #5bc0de;
$danger-color: #c32626;
$success-color: #5cb85c;
$background-primary-color: #197A56;
$white-color: #fff;
$white-text: #fff;
$grey-text: #606060;
$primary-color-hover: #176f4f; //#2180c9;
$primary-border-color: #298060; //#96c8da;

// Font Size Variables
$xs-font: 0.5rem;
$sm-font: 0.75rem;
$md-font: 0.88rem;
$lg-font: 1rem;
$x1lg-font: 1.25rem;
$x2lg-font: 1.5rem;
$x3lg-font: 1.75rem;
$x4lg-font: 2rem;

// Padding Variables
$padding-0: 0rem;
$padding-1: 0.25rem;
$padding-2: 0.5rem;
$padding-3: 0.75rem;
$padding-4: 1rem;
$padding-5: 1.25rem;
$padding-6: 1.5rem;
$padding-7: 1.75rem;
$padding-8: 2rem;
$padding-9: 2.25rem;
$padding-10: 2.5rem;
$padding-11: 2.75rem;
$padding-12: 3rem;

// Margin Variables
$margin-0: 0rem;
$margin-1: 0.25rem;
$margin-2: 0.5rem;
$margin-3: 0.75rem;
$margin-4: 1rem;
$margin-5: 1.25rem;
$margin-6: 1.5rem;
$margin-7: 1.75rem;
$margin-8: 2rem;

// Screen Resolution Variables
$mobile-min: 320px;
$mobile-max: 767px;

$tab-min: 768px;
$tab-max: 1100px;

$dekstop: 1101px;
$large-monitor: 1920px;
